<template>
	<div>
		<!-- Intro to understand the stage -->
		<StageIntroDialog />

		<!-- Research Question -->
		<ResearchQuestion></ResearchQuestion>

		<div class="mt-4">
			<v-alert
				:icon="$vuetify.icons.values.bulb_on"
				border="left"
				colored-border
				color="warning"
				elevation="4"
				class="font-weight-bold mb-0 py-5"
			>
				<v-row>
					<v-col>
						{{ $t("app.collaborate_research_paper_info") }}
					</v-col>
					<v-col align="end" sm="2">
						<AppButton
							label="app.share_research_paper"
							color="success"
							icon="share"
							@click="share_dialog = true"
						></AppButton>
					</v-col>
				</v-row>
			</v-alert>

			<!-- Research paper component -->
			<ResearchPaper v-if="template && template.length"></ResearchPaper>
		</div>

		<!-- Finish Stage Dialog -->
		<ShareDialog
			v-if="share_dialog"
			v-model="share_dialog"
			:dialog="share_dialog"
			:uuid="collaborationId"
			label="app.share_research_paper"
			desc="app.share_research_paper_code_instructions"
			@close="share_dialog = false"
		></ShareDialog>

		<!-- Finish Stage Dialog -->
		<StageFinishDialog
			v-if="dialog"
			v-model="dialog"
			:dialog="dialog"
			current_step="app.research_paper.title"
			next_step="app.write_research.summary.title"
			@close="dialog = false"
		></StageFinishDialog>
	</div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ResearchPaper from "@/components/research/modules/write/stages/researchPaper/Index.vue";
import StageFinishDialog from "@/components/research/modules/_common/stages/FinishDialog";
import ResearchQuestion from "@/components/research/modules/_common/ResearchQuestion.vue";
import { generateRandomString } from "@/utils/helpers";
import ShareDialog from "@/components/shared/ShareDialog.vue";
import StageIntroDialog from "@/components/research/modules/_common/stages/IntroDialog";

export default {
	name: "ResearchPaperPage",

	data() {
		return {
			dialog: false,
			share_dialog: false,
		};
	},

	components: {
		ResearchPaper,
		StageFinishDialog,
		ResearchQuestion,
		ShareDialog,
		StageIntroDialog,
	},

	computed: {
		...mapState({
			template: (state) => state.researchPaper.template,
		}),

		...mapGetters({
			userProfile: "user/getProfile",
		}),

		collaborationId() {
			return `rp_${generateRandomString()}-${this.userProfile.id}`;
		},
	},

	created() {
		this.readyToListenEvents();
		this.getTemplate();
	},

	methods: {
		readyToListenEvents() {
			this.$eventBus.$on("rp-finish", (payload) => {
				this.dialog = true;
			});
		},

		async getTemplate() {
			try {
				this.$loader.start();

				var response = await this.$store.dispatch("researchPaper/template", {
					clear: false,
				});

				/**
				 * initial template needs to be requested when clear is true
				 * -- can't do it every time as it will erase the content of template ---
				 */
				if (!response.length) {
					this.$store.dispatch("researchPaper/template", {
						clear: true,
					});
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},

	beforeDestroy() {
		this.$eventBus.$off("rp-finish");
	},
};
</script>
